import * as React from 'react'
import Layout from '../../components/Layout/Layout'
import Banner from '../../components/Banner/Banner'
import Button from '../../components/Button/Button'
import Title from '../../components/Title/Title'
import Text from '../../components/Text/Text'
import Underline from '../../components/Underline/Underline'
import { Helmet } from 'react-helmet'

function FootPain() {
    return (
        <Layout>
            <Helmet>
                <title>Ankle and Foot Pain - Conditions we treat | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <Title blue={true}>
                <h1>Ankle and Foot Pain</h1>
            </Title>
            <section>
                <Text>
                    <p>Pain can occur in the foot and ankles for a number of reasons. The foot and ankle are made up of a number of small bones interconnected by ligaments, muscles and fascia all working together to give strength, stability and flexibility the foot and ankle needs to function properly. Common conditions of the foot, ankle and areas which can give rise to pain include:</p>
                    <ul>
                        <li><strong>Acquired flat foot</strong> - when the inner side of the foot or inner arch flattens. The foot may roll over to the inner side. It is often apparent if the heels of shoes wear out quickly and unevenly. It can damage the ankle joint and achilles tendon (the tendon at the back of your ankle) and can also cause shin pain. Symptoms can include, pain, swelling, change in foot shape and knee pain or swelling.</li>
                        <li><strong>Plantar fasciitis</strong> - s often a sharp pain and inflammation in the plantar fascia – the tough fibrous band of tissue that supports the arches of the foot and runs under the small bones from the underside of the heel and sole towards the toes. It tends to be made worse by standing for long periods of time in poor footwear, being off the feet for a long time and putting the foot on the floor first thing in the morning. The sole of the foot can occasionally feel a little numb, tingly or swell slightly.</li>
                        <li><strong>Achilles pain</strong> - The Achilles tendon is formed by the tendon of the two calf muscles, coming together and attaching on to the bone at the back of the heel. Pain, inflammation or tendonitis can cause pain and tightness in this area.</li>
                        <li><strong>Sprained ankle</strong> - Typically the result of a sudden twisting or “going over” on the ankle joint.  Typical symptoms are swelling, bruising, pain and instability of the ankle. Sometimes an x-ray is required to rule out any fracture. Rest, ice, elevation and compression can help in the first 24 to 48 hours after injury.</li>
                    </ul>
                </Text>
                <Text>
                    <Underline>
                        <h2>How Can an Osteopath Help?</h2>
                    </Underline>
                    <p>Depending on diagnosis, age and fitness, a variety of gentle massage and mobilisation techniques can increase mobility in the joints and the flexibility of the muscles in the foot.</p>
                    <p>Often muscles and joints in lower limbs, knee, hip and lower back will be looked at and any joint restrictions and muscle tightness may be treated to improve the movement in the joints of the lower body will help the foot and ankle function better.</p>
                    <p>Specific balancing, strengthening or loosening exercises may also be offered, along with advice on strapping and brace supports, footwear and any lifestyle factors that might be hindering healing. You may also be referred to a podiatrist.</p>
                    <p>X-rays, scans or other tests may be required to make a diagnosis and a referral to a GP may be made for any additional investigations and treatment, such as advice on pain killers and anti-inflammatory medications.</p>
                </Text>
            </section>
            <section style={{backgroundColor: 'var(--primary-800)', color: 'white'}}>
                <Banner>
                    <h2>Make an appointment with us.</h2>
                    <Button external={true} type="neutralOutline" to="https://eubookings.nookal.com/bookings/location/GGNJC">Book online today</Button>
                </Banner>
            </section>
        </Layout>
    )
}

export default FootPain